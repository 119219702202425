import React from 'react';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
// import addHttp from './helpers/utilities/addHttp';
import isEmpty from './helpers/utilities/isEmptyObject';
import languageMapping from './helpers/utilities/languageMapping';
import getMinutes from './helpers/utilities/getMinutes';
import NavbarBasic from './components/NavbarBasic/NavbarBasic';
import AuthorizationError from './AuthorizationError';
import config from './config';
import {
  authenticationService,
  environment,
} from './helpers/services';

import dataDefault from './data/default.json';

const domainValue = config.cookieDomain[environment];

const {
  CochlearDotCom,
  deviceSupport,
  store,
  profStore,
  // authenticate,
  gql,
} = config;
// const { cimLogout } = authenticate[environment];
const { scCd, apiKey, authToken } = gql[environment];

const deviceSupportHome = deviceSupport[environment];
const storeHome = store[environment];
const profStoreHome = profStore[environment];
const cochlearHome = CochlearDotCom[environment];

// get the ref app and return app value and homepage
function getRefApp(app) {
  // dcx, ds, store, dm
  let appInfo = {};
  switch (app) {
    case 'dcx':
      appInfo = {
        ref: 'dcx',
        homePage: cochlearHome,
      };
      break;
    case 'ds':
      appInfo = {
        ref: 'ds',
        homePage: deviceSupportHome,
      };
      break;
    case 'store':
      appInfo = {
        ref: 'store',
        homePage: storeHome,
      };
      break;
    case 'prof-store':
      appInfo = {
        ref: 'prof-store',
        homePage: profStoreHome,
      };
      break;
    default:
      appInfo = {
        ref: 'dcx',
        homePage: cochlearHome,
      };
  }
  return appInfo;
}

// pass in obj from array
function getPair(toObject, fromObject) {
  // return new key value pair to new labels object
  return (toObject[fromObject.key.value] = fromObject.value.value);
}

class Authorizer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false, // whether or not to show an error message
      errorType: 'networkError', // 'noAccess', 'noAccessProvisional', 'networkError'
      errorStatus: null, // server error returned from login call
      fade: false, // current fade state of the loader
      spinnerCount: -1, // step through the array
      spinnerText: dataDefault.spinner, // array of text messages for the spinner
      refApp: 'dcx', // app user came from
      refHomePage: cochlearHome,
      errorData: {},
      defaultErrorData: dataDefault.data,
    };
    this.fadeIn = this.fadeIn.bind(this);
    this.fadeOut = this.fadeOut.bind(this);
  }

  componentDidMount() {
    // const { refHomePage } = this.state;
    const { location } = window;
    const { search } = location;
    const parsed = queryString.parse(search);
    // query strings in url
    const {
      code,
      error: callbackError,
      request,
      origin,
      app,
      state,
    } = parsed;

    // Get user specific data if user cookie exists
    const user = Cookies.get('currentUser');

    if (user) {
      const decoded = user && jwtDecode(user);
      // get locale
      const locale = decoded && decoded.locale;
      // get country
      const country =
        decoded && decoded['https://www.cochlear.com/country_code'];

      (async () => {
        let localeData;
        if (locale) {
          const languageCode = locale.substring(0, 2).toLowerCase();
          const countryCode = country.toUpperCase();

          if (languageCode && countryCode) {
            try {
              localeData = await import(
                `./data/locale/${languageCode}-${countryCode}.json`
              );
              if (localeData) {
                this.setState({
                  spinnerText: localeData.spinner,
                  defaultErrorData: localeData.data,
                });
              }
            } catch (error) {
              console.error(`Failed to load locale data: ${error}`);
            }
          }
        }
      })();
    }

    // add class to fade in text message below the spinner
    this.timer = setTimeout(this.fadeIn, 2500);

    // I have a code, call the endpoint and return token
    /* */
    if (code) {
      authenticationService.login(code).then(
        (user) => {
          // console.log('JWT Id Token: (the user info)');
          // console.log(user);

          // const user = Cookies.get('currentUser');
          const decoded = user && jwtDecode(user);

          // console.log('Decoded user info:');
          // console.log(decoded);

          // get app access
          const appAccess =
            decoded && decoded['https://www.cochlear.com/app'];

          // console.log(`appAccess: ${appAccess}`);

          // get user type
          const getUserType =
            decoded && decoded['https://www.cochlear.com/user_type'];

          // console.log(`getUserType: ${getUserType}`);

          const userType =
            getUserType &&
            getUserType.toLowerCase().includes('provisional')
              ? 'provisional'
              : getUserType;

          // console.log(`userType: ${userType}`);

          // get locale
          const locale =
            decoded &&
            (decoded.locale ||
              decoded['https://www.cochlear.com/language']);

          // console.log(`locale: ${locale}`);

          // get user country
          const countryCode =
            decoded &&
            decoded['https://www.cochlear.com/country_code'];

          // console.log(`countryCode: ${countryCode}`);

          // get ref via the app query parameter or the state object, if this is a redirect from auth0
          let resolvedApp = app;
          if (!app && state) {
            const stateVal = decodeURIComponent(
              Buffer.from(state, 'base64'),
            );
            let decodedState = stateVal;
            try {
              decodedState = JSON.parse(stateVal);
              resolvedApp = decodedState.app;
            } catch (err) {
              resolvedApp = 'default';
            }
            // console.log("resolved app from redirect state", resolvedApp);
          }
          const ref = getRefApp(resolvedApp);
          // console.log('ref: ', ref);

          // Does the user have any app claims?
          // if they have app access redirect them to the referrer url or app homepage
          if (appAccess) {
            // Gets the cookie that was stored when the user clicked on login or raise service request
            const getReferrerUrl = Cookies.get('referrerUrl');

            // Remove cookie as it's no longer needed
            // We don't want it to stay here anymore
            if (getReferrerUrl) Cookies.remove('referrerUrl');

            // Check app value and return to app homepage instead if referrer cookie doesnt exist
            const goToPage = getReferrerUrl || ref.homePage;
            // console.log(`Goto: ${goToPage}`);
            window.location.replace(goToPage);
            return null;
          }

          // catch null and undefined (user has token but no app authorization)
          if (!appAccess) {
            if (countryCode) {
              const userErrorData = {
                countryCode,
                locale,
                userType,
                refApp: ref.ref,
                refHomePage: ref.homePage,
              };

              (async () => {
                // call the error message api
                const message = await this.getErrorMessages(
                  userErrorData,
                );
                console.log('🚀 ~ Authorizer ~ message:', message);

                if (
                  message.labels === null ||
                  message.screens === null
                ) {
                  // case #1: error as in the gql request returns null for lables or screens, eg: no access - non-drx enabled country, so redirect user

                  // redirect non-drx enabled country users to dcx
                  window.location.replace(cochlearHome);
                  return false;
                } else if (userType === 'provisional') {
                  // check if provisional

                  // show provisional error message with logout
                  this.setError(
                    'provisional',
                    userErrorData,
                    '',
                    message,
                  );
                } else if (userType) {
                  // show noAccess error
                  this.setError(
                    'noAccess',
                    userErrorData,
                    '',
                    message,
                  );
                } else {
                  // case #2: the qgl is down/unavailable, eg: network error
                  this.setError('networkError', '', message);
                }
              })();

              return false;
            }
          }
        },
        (error) => {
          this.setError('networkError', '', error);
        },
      );
    }

    // I have a request to logout
    if (request) {
      // we are logging out so don't show text messaging with the spinner
      this.setState({
        spinnerText: [],
      });
      if (origin) {
        Cookies.set('referrerUrl', `//${origin}`, {
          domain: domainValue,
          secure: true,
          expires: getMinutes(120),
        });
      }
      authenticationService.logout();
    }

    // code not returned, received an error code instead
    if (callbackError) {
      this.setError('networkError');
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  // setError is called when :
  // 1) Code is not returned to the auth page due to server/network issue (type = networkError, no data, no error code)
  // 2) Code is returned, user token failed to be created (type = networkError, no data, error code)
  // 3) Code is returned, user token created but user has no app claim (type exists, data exists, no error code)
  setError(type, tokenData, error, errorData) {
    clearTimeout(this.timer);
    const errorStatus =
      error && error.response ? error.response.status : '';
    this.setState({
      error: true,
      errorType: type,
      errorStatus: errorStatus || null,
      refApp: tokenData && tokenData.refApp,
      refHomePage: tokenData && tokenData.refHomePage,
      errorData: errorData || {},
    });
  }

  async getErrorMessages(userErrorData) {
    const { countryCode, locale } = userErrorData;

    const language = languageMapping(locale, countryCode);

    // GQL query to return the screen image/message and button labels
    const queryData = JSON.stringify({
      query: `
      {
        labels: item(path: "/sitecore/content/drx/${countryCode.toUpperCase()}/Configuration/Error data/labels", language: "${language}") {
          results: children {
            key: field(name: "key") {
              value
            }
            value: field(name: "value") {
              value
            }
          }
        }
        screens: item(path: "/sitecore/content/drx/${countryCode.toUpperCase()}/Configuration/Error data/screens", language: "${language}") {
          results: children {
            name
            items: children {
              name
              title: field(name: "title") {
                value
              }
              text: field(name: "text") {
                value
              }
              image: field(name: "image") {
                value
              }
            }
          }
        }
      }     
      `,
    });

    const errorScreensUrl = `https://${scCd}/api/cochlear?sc_apikey=${apiKey}&sc_site=drx-us-en`;

    try {
      const response = await axios.post(errorScreensUrl, queryData, {
        headers: {
          Authorization: authToken && `Basic ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      const { data } = response.data;
      return data;
    } catch (error) {
      console.log(
        '🚀 ~ Authorizer ~ getErrorMessages ~ error:',
        error,
      );
      return error;
    }
  }

  fadeIn() {
    // add a class to the text message
    // the fade in effect is handled by the css
    // each fadeIn call steps through the array of messages
    // and calls the fadeOut function

    const { spinnerCount, spinnerText, fade } = this.state;

    if (spinnerCount + 1 !== spinnerText.length) {
      this.setState({ spinnerCount: spinnerCount + 1 });
    } else {
      this.setState({ spinnerCount: 0 });
    }

    this.setState({ fade: !fade });
    this.timer = setTimeout(this.fadeOut, 5000);
  }

  fadeOut() {
    // remove a class on the text message
    // to fade out the text
    const { fade } = this.state;
    this.setState({ fade: !fade });
    this.timer = setTimeout(this.fadeIn, 5000);
  }

  render() {
    const { location } = window;
    const {
      error,
      errorStatus,
      fade,
      spinnerText,
      spinnerCount,
      errorType,
      refApp,
      refHomePage,
      errorData, // object of objects
      defaultErrorData,
    } = this.state;

    const { search } = location;
    const parsed = queryString.parse(search);
    // it can then be used as fallback for callback url if needed
    const { code, error: callbackError, request } = parsed;

    const Navigation = () => <NavbarBasic />;

    // used when the api call to fetch error screens fails
    const {
      screens: defaultData,
      labels: defaultLabels,
    } = defaultErrorData;

    // data retrieved from get call
    const { screens, labels } = errorData;

    // lets get the data we need for our error screen scenario and build our objects
    let screenData = {};
    const screenLabels = {};

    // we have dynamic data and not an network error
    if (
      errorType &&
      errorType !== 'networkError' &&
      errorData &&
      !isEmpty(errorData)
    ) {
      const resultType = screens.results.find(
        (obj) => obj.name === errorType,
      );
      const resultApp = resultType.items.find(
        (obj) => obj.name === refApp,
      );

      // create labels oject of strings
      // loop through array
      labels.results.forEach((value) => {
        // Do stuff with value or index
        getPair(screenLabels, value);
      });
      screenData = resultApp;
    } else {
      // network error, use default local error object
      defaultLabels.results.forEach((value) => {
        // Do stuff with value or index
        getPair(screenLabels, value);
      });
      screenData = defaultData.results[0].items[0];
    }

    if (error && screenData && !isEmpty(screenData)) {
      return (
        <>
          <div className="app">
            <div className="wrapper">
              <Navigation />
              <main role="main" id="main" tabIndex="-1">
                <AuthorizationError
                  errorData={screenData}
                  labels={screenLabels}
                  errorResponse={errorStatus}
                  errorType={errorType}
                  refHomePage={refHomePage}
                />
              </main>
            </div>
          </div>
        </>
      );
    }

    /* visitors will get redirect to the login page if they dont have a code or error */
    if (!callbackError && !code && !request) {
      // if window is undefined, redirect to login page wont work
      if (typeof window !== 'undefined') {
        // remove cookie and redirect to login page
        authenticationService.renew();
        return false;
      }
      return window.location.replace(refHomePage);
    }

    /* render a spinner whilst the background logic takes place */
    return (
      <div className="section spinner" style={{ flexGrow: 1 }}>
        <div className="spinner__content">
          <div className="spinner__loading" />
          <div
            className={`spinner__text fader ${
              fade ? '' : 'fadedOut'
            }`}
          >
            <p>
              <br />
              &shy;
              {spinnerText[spinnerCount]}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Authorizer;
